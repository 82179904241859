import { lazy } from "react";

const allAreaComponents = {
  landing: lazy(() => import("../../pages/dashboard/pages/landing/index")),
  categories: {
    main: lazy(() => import("../../pages/dashboard/pages/categories/index")),
  },
  tags: {
    main: lazy(() => import("../../pages/dashboard/pages/tags/index")),
  },
  courses: {
    main: lazy(() => import("../../pages/dashboard/pages/courses/index")),
    addCourse: lazy(
      () => import("../../pages/dashboard/pages/courses/addCourse")
    ),
    updateCourse: lazy(
      () => import("../../pages/dashboard/pages/courses/updateCourse")
    ),
    view: lazy(() => import("../../pages/dashboard/pages/courses/viewCourse")),
    lessons: {
      addLesson: lazy(
        () => import("../../pages/dashboard/pages/courses/viewCourse/sections/addLesson")
      ),
      updateLesson:lazy(
        () => import("../../pages/dashboard/pages/courses/viewCourse/sections/updateLesson")
      ),
    },
  },
  profile:lazy(() => import("../../pages/dashboard/pages/profile/index")),
  coupons: {
    main: lazy(() => import("../../pages/dashboard/pages/coupons/index")),
  },
  orders: {
    main: lazy(() => import("../../pages/dashboard/pages/orders/index")),
    view: lazy(() => import("../../pages/dashboard/pages/orders/viewOrder")),
  },
  settings: {
    hero: lazy(() => import("../../pages/dashboard/pages/settings/hero")),
    faqs: lazy(() => import("../../pages/dashboard/pages/settings/faqs")),
  },
};

export default allAreaComponents;

/*  ---------------------------------------------------------------------- */
