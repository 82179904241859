import { Spinner } from "react-bootstrap";

const Loader = ({
    nonVerticalCenterable,
}: {
    nonVerticalCenterable?: boolean;
}) => {
    return (
        <div
            style={{ height: !nonVerticalCenterable ? "100vh" : "100%" }}
            className={`d-flex justify-content-center align-items-center`}
        >
            <Spinner
                style={{ height: "3rem", width: "3rem" }}
                className="spinner-border text-success"
            />
        </div>
    );
};

export default Loader;

/*  ---------------------------------------------------------------------- */