import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  tokens: { accessToken: null; refreshToken: null };
} = {
  tokens: { accessToken: null, refreshToken: null },
};

const authTokenSlice = createSlice({
  name: "authToken",
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
  },
});

export const { setTokens } = authTokenSlice.actions;

export default authTokenSlice.reducer;

/*  ---------------------------------------------------------------------- */