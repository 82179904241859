import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import logoDark from "../../../assets/images/png/logo-dark-text.png";
import logoLetter from "../../../assets/images/png/logo-letter.png";
import RightPanelBar from "../../rightBarPanel";
import NavItems from "./navItems";
import ProfileBar from "../../profile/bar";
import ConfirmModal from "../../modals/confirmationModal";
import { deleteCookie, setCookie } from "../../../services/utils/cookie";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/slices/loggedInUser.slice";
import { setTokens } from "../../../redux/slices/authToken.slice";

type HeaderProps = {
  handleSidebar: (value: boolean) => void;
  isCollapsed: boolean;
};

const Header = ({ handleSidebar, isCollapsed }: HeaderProps) => {
  const [isVisiblePanelBar, setIsVisiblePanelBar] = useState<boolean>(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    try {
      deleteCookie("elearning_authenticated");
      deleteCookie("elearning_user");
      dispatch(setTokens(null));
    } catch (error) {
      console.log(error, "Error occurs while logout!");
    }
  };

  return (
    <React.Fragment>
      <header style={{ background: "white" }} className="main-header">
        <div className="d-flex align-items-center logo-box justify-content-start">
          <div className="logo">
            <div className="logo-mini w-30">
              <span className="light-logo">
                <img src={logoLetter} alt="logo" />
              </span>
              <span className="dark-logo">
                <img src="../../../images/logo-letter-white.png" alt="logo" />
              </span>
            </div>
            <div className="logo-lg">
              <span className="light-logo">
                <img src={logoDark} alt="logo" />
              </span>
              <span className="dark-logo">
                <img src={logoDark} alt="logo" />
              </span>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-static-top">
          <div className="app-menu">
            <ul className="header-megamenu nav">
              <li className="btn-group nav-item">
                <RxHamburgerMenu
                  onClick={() => handleSidebar(!isCollapsed)}
                  className=" menu waves-effect waves-light nav-link push-btn btn-primary-light ms-0"
                />
              </li>
              <li className="btn-group d-lg-inline-flex d-none">
                <div className="app-menu">
                  <div className="search-bx mx-5">
                    <form>
                      <div className="input-group">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn"
                            type="submit"
                            id="button-addon3"
                          >
                            <i className="icon-Search">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <NavItems managePanelBar={(value) => setIsVisiblePanelBar(value)} />
        </nav>
      </header>
      <RightPanelBar
        isVisible={isVisiblePanelBar}
        setIsVisible={setIsVisiblePanelBar}
      >
        <ProfileBar
          handleLogout={() => {
            setIsShowConfirmModal(true);
          }}
          handleCloseProfile={() => setIsVisiblePanelBar(false)}
        />
      </RightPanelBar>
      <ConfirmModal
        isShowModal={isShowConfirmModal}
        setIsShowModal={setIsShowConfirmModal}
        handleConfirm={handleLogout}
        title="Do you want to logout?"
      />
    </React.Fragment>
  );
};

export default Header;

/*  ---------------------------------------------------------------------- */
