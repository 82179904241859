import { useLocation, useNavigate } from "react-router-dom";
import allAreaRoutes from "../../../services/utils/routes";
import MenuList from "../../menuList";
import { useState } from "react";

type SidebarProps = {
  isCollapsed: boolean;
};

const Sidebar = ({ isCollapsed }: SidebarProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <aside
        className={`main-sidebar ${isCollapsed ? "sidebar-collapse" : ""}`}
      >
        <section className="sidebar position-relative">
          <div className="multinav">
            <div className="multinav-scroll" style={{ height: "97%" }}>
              <ul className="sidebar-menu" data-widget="tree">
                {allAreaRoutes
                  .filter((route) => !route?.isHidden)
                  .map(
                    ({ title, icon: Icon, children, route, activeRoutes }) => {
                      if (children && children?.length > 0)
                        return (
                          <MenuList
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            menuIcon={Icon}
                            menuTitle={title}
                            menuList={children}
                          />
                        );
                      return (
                        <li
                          style={{ cursor: "pointer" }}
                          className={`${
                            pathname === route ||
                            activeRoutes?.find((route) =>
                              pathname.includes(route.toString())
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <span
                            onClick={() => {
                              setIsOpen(false);
                              navigate(route.toString());
                            }}
                          >
                            <Icon />
                            {(!isCollapsed || window.innerWidth <= 766) && (
                              <span>{title}</span>
                            )}{" "}
                          </span>
                        </li>
                      );
                    }
                  )}
              </ul>
            </div>
          </div>
        </section>
      </aside>
    </div>
  );
};

export default Sidebar;

/*  ---------------------------------------------------------------------- */
