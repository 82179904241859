import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "../../services/utils/cookie";

const initialState: {
  user: any;
} = {
  user: getUser(),
};

const authUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = authUserSlice.actions;

export default authUserSlice.reducer;

/*  ---------------------------------------------------------------------- */