import { ILoginUserResponse } from "../../../interfaces/response/login.response";
import baseInstance from "../../utils/baseInstance";

const SignUp = (data: { email: string; password: any }) => {

  return baseInstance
    .post("/auth/register", data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
const Login = (data: {
  email: string;
  password: any;
}): Promise<ILoginUserResponse> => {

  return baseInstance
    .post("/auth/login", data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
export default { SignUp, Login };

/*  ---------------------------------------------------------------------- */