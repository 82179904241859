import { useSelector } from "react-redux";
import userAvatar from "../../../assets/images/png/avatar.png";
import { getUser } from "../../../services/utils/cookie";

const NavItems = ({
  managePanelBar,
}: {
  managePanelBar: (value: boolean) => void;
}) => {
  const {user}=useSelector((state:any)=>state.loggedInUser)

  return (
    <div className="navbar-custom-menu r-side">
      <ul className="nav navbar-nav">
        <li className="dropdown user user-menu">
          <span
            className="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent p-0 no-shadow"
            title="User"
            data-bs-toggle="modal"
            data-bs-target="#quick_user_toggle"
          >
            <div
              onClick={() => managePanelBar(true)}
              className="d-flex pt-1 align-items-center"
            >
              <div className="text-end me-10">
                <p className="pt-5 fs-14 mb-0 fw-700 pb-1">{user?.name}</p>
                <small className="fs-10 mb-0 text-uppercase text-mute">
                  Admin
                </small>
              </div>
              <img
                src={user?.avatar?.url??userAvatar}
                className="avatar rounded-circle bg-primary-light h-40 w-40"
                alt=""
              />
            </div>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default NavItems;

/*  ---------------------------------------------------------------------- */