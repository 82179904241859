const DASHBOARD_ROUTES = {
  DASHBOARD: "/",
  PROFILE:"/profile",
  CATEGORIES: "/categories",
  TAGS: "/tags",
  COURSES: {
    LIST: "/courses",
    ADD_COURSE: "/courses/add-course",
    UPDATE_COURSE: "/courses/update-course/:id",
    VIEW: "/courses/:id",
    LESSONS: {
      ADD_LESSON: "/courses/:id/sections/:id/add-lesson",
      UPDATE_LESSON:"/courses/:id/sections/:id/update-lesson/:id"
    },
  },
  COUPONS: {
    LIST: "/coupons",
  },
  ORDERS: {
    LIST: "/orders",
    VIEW: "/orders/:id",
  },
  SETTINGS: {
    HERO: "/settings/hero",
    FAQ: "/settings/faq",
  },
};

export const allAreaPath = {
  ...DASHBOARD_ROUTES,
};

/*  ---------------------------------------------------------------------- */
