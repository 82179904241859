import axios from "axios";

const baseInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ELEARNING_ADMIN_SERVER_BASE_URL,
    withCredentials: true,
  });

  axiosInstance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
export default baseInstance();

/*  ---------------------------------------------------------------------- */
