import { toast } from "react-toastify";

export const successToast = (data: any) => {
  return toast(data, { type: "success" });
};

export const errorToast = (data: any) => {
  return toast(data, { type: "error" });
};

export const warningToast = (data: any) => {
  return toast(data, { type: "warning" });
};

/*  ---------------------------------------------------------------------- */
