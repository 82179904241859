import React, { useEffect, useState } from "react";

type StandardModalProps = {
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
  children: React.ReactNode;
  title?: string;
};

const StandardModal = ({
  isShowModal,
  setIsShowModal,
  title,
  children,
}: StandardModalProps) => {
  const [isTransForm, setIsTransForm] = useState(false);

  useEffect(() => {
    if (isShowModal) {
      const timer = setTimeout(() => {
        setIsTransForm(true);
      }, 20);
      return () => clearTimeout(timer);
    } else setIsShowModal(false);
  }, [isShowModal, setIsShowModal]);

  return (
    <div className="tab-content ">
      <div
        className={`tab-pane  active ${isShowModal ? "show" : ""}`}
        id="bs-modals-preview"
      >
        <div
          style={{ display: isShowModal ? "block" : "none" }}
          id="centermodal"
          aria-modal={isShowModal ? true : false}
          className={`modal fade ${isShowModal ? "show" : ""}`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="standard-modalLabel"
        >
          <div
            style={{ transform: isTransForm ? "none" : "translate(0,-50px)" }}
            className="modal-dialog modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="modal-header">
                {title && (
                  <h4 className="modal-title" id="standard-modalLabel">
                    {title}
                  </h4>
                )}

                <button
                  onClick={() => {
                    setIsShowModal(false);
                    setIsTransForm(false);
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardModal;

/*  ---------------------------------------------------------------------- */
