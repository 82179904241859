import React from "react";
import AppButton from "../button";
import StandardModal from "./standard.modal";

type ConfirmModalProps = {
  title: string;
  isShowModal: boolean;
  setIsShowModal: (value: boolean) => void;
  children?: React.ReactNode;
  handleConfirm: () => void;
  okButtonText?: string;
  loading?: boolean;
};

const ConfirmModal = ({
  isShowModal,
  setIsShowModal,
  title,
  children,
  handleConfirm,
  okButtonText,
  loading,
}: ConfirmModalProps) => {
  return (
    <StandardModal
      isShowModal={isShowModal}
      setIsShowModal={setIsShowModal}
      title={title}
    >
      {children}
      <div style={{ display: "flex", justifyContent: "end", gap: "0 10px" }}>
        <button
          onClick={() => setIsShowModal(false)}
          style={{ border: "1px solid #00e096" }}
          type="button"
          className="btn text-primary "
        >
          Cancel
        </button>
        <AppButton
          isLoading={loading}
          className="btn-primary"
          onClick={() => handleConfirm()}
        >
          {okButtonText ?? "Submit"}
        </AppButton>
      </div>
    </StandardModal>
  );
};

export default ConfirmModal;

/*  ---------------------------------------------------------------------- */
