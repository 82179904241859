import React from "react";

type RightPanelBaProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  children: React.ReactNode;
};

const RightPanelBar = ({
  isVisible,
  setIsVisible,
  children,
}: RightPanelBaProps) => {
  return (
    <div
      className={`modal modal-right fade ${isVisible ? "show" : ""}`}
      style={{ display: isVisible ? "block" : "" }}
      id="quick_user_toggle"
    >
      <div className="modal-dialog">
        <div className="modal-content slim-scroll3">{children}</div>
      </div>
    </div>
  );
};

export default RightPanelBar;

/*  ---------------------------------------------------------------------- */
