import { useSelector } from "react-redux";
import AuthHandler from "../pages/auth";

const authenticate = (WrappedComponent: any) => {
  const Component = (props: any) => {
    const { tokens } = useSelector((state: any) => state.authToken);

    if (!tokens?.accessToken) {
      return <AuthHandler {...props} />;
    }
    return <WrappedComponent {...props} />;
  };

  return <Component />;
};

export default authenticate;

/*  ---------------------------------------------------------------------- */