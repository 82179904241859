import {ILoginUser} from "../../interfaces/response/login.response";

export const setCookie = (cookieName: string, cookieValue: any) => {
  try {
    document.cookie = `${cookieName}=${JSON.stringify(cookieValue)}`;
  } catch (error) {
    throw new Error("Cookie doesn't set");
  }

  return { success: true };
};

export const deleteCookie = (cookieName: string) => {
  document.cookie =
    cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  return true;
};

export const getCookie = (name: string) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const getUser = () => {
  let user = null;
  try {
    user = getCookie("elearning_user");

    if (user) {
      user = JSON.parse(user);
    }
  } catch (error) {
    throw new Error("User doesn't exists!");
  }
  return user as ILoginUser;
};

/*  ---------------------------------------------------------------------- */
