import { Formik } from "formik";
import { FaLock, FaRegUser } from "react-icons/fa";
import AppButton from "../../../components/button";
import { AuthHandlerType } from "../../../enums/auth";
import { loginValidation } from "../../../validations/auth/login.validation";

const LoginForm = ({
  toggleHandler,
  loading,
  handleLogin,
}: {
  toggleHandler: (value: AuthHandlerType) => void;
  handleLogin: (values: { email: string; password: any }) => void;
  loading?: boolean;
}) => {
  return (
    <div>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginValidation}
        onSubmit={(values) => {
          handleLogin(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <div className="input-group mb-1">
                <span className="input-group-text bg-transparent">
                  <FaRegUser className="text-fade ti-user" />
                </span>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  type="text"
                  name="email"
                  className="form-control ps-15 bg-transparent"
                  placeholder="Email"
                />
              </div>
              {errors.email && errors.email && touched.email && (
                <span className="text-xs text-danger ">
                  {errors.email && touched.email && errors.email}
                </span>
              )}
            </div>
            <div className="form-group mb-3">
              <div className="input-group mb-1">
                <span className="input-group-text  bg-transparent">
                  <FaLock className="text-fade ti-lock" />
                </span>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  type="password"
                  name="password"
                  className="form-control ps-15 bg-transparent"
                  placeholder="Password"
                />
              </div>
              {errors.password && errors.password && touched.password && (
                <span className="text-xs text-danger ">
                  {errors.password && touched.password && errors.password}
                </span>
              )}
            </div>
            <div className="row">
              <div className="col-6">
                <div className="checkbox">
                  <input type="checkbox" id="remember_me" />
                  <label htmlFor="remember_me">Remember Me</label>
                </div>
              </div>
              <div className="col-6">
                <div className="fog-pwd text-end">
                  <span
                    onClick={() =>
                      toggleHandler(AuthHandlerType.FORGOT_PASSWORD)
                    }
                    className="text-primary cursor-pointer fw-500 hover-primary"
                  >
                    <FaLock className="ion ion-locked" />
                    Forgot pwd?
                  </span>
                  <br />
                </div>
              </div>
              <div className="col-12 text-center">
                <AppButton
                  isLoading={loading}
                  className="btn btn-primary w-p100 mt-10"
                  type="submit"
                >
                  SIGN IN
                </AppButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;

/*  ---------------------------------------------------------------------- */
