import { FaRegUser } from "react-icons/fa";
import { AuthHandlerType } from "../../../enums/auth";

const ForgotPassword = ({
  toggleHandler,
}: {
  toggleHandler: (value: AuthHandlerType) => void;
}) => {
  return (
    <div className="container h-p100">
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center g-0">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded10 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="mb-10 fw-600 text-primary">
                    Forgot Password ?
                  </h2>
                  <p className="mb-0 text-fade">
                    Enter your email to reset your password.
                  </p>
                </div>
                <div className="p-40">
                  <form action="index.html" method="post">
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-transparent">
                          {" "}
                          <FaRegUser className="text-fade ti-user" />
                        </span>
                        <input
                          type="email"
                          className="form-control ps-15 bg-transparent"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary w-p100 mt-10"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

/*  ---------------------------------------------------------------------- */