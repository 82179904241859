import { FaIntercom, FaQuestion, FaTags } from "react-icons/fa";
import { FaFirstOrder } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { MdCategory, MdDashboard } from "react-icons/md";
import { RiCoupon2Fill } from "react-icons/ri";
import { SiCoursera } from "react-icons/si";
import allAreaComponents from "./dynamicComponents";
import { allAreaPath } from "./pathConstants";

const allAreaRoutes = [
  {
    title: "Dashboard",
    icon: MdDashboard,
    route: allAreaPath.DASHBOARD,
    index: true,
    path: allAreaComponents.landing,
  },
  {
    title: "Categories",
    icon: MdCategory,
    route: allAreaPath.CATEGORIES,
    path: allAreaComponents.categories.main,
  },
  {
    title: "Tags",
    icon: FaTags,
    route: allAreaPath.TAGS,
    path: allAreaComponents.tags.main,
  },
  {
    title: "Courses",
    icon: SiCoursera,
    route: allAreaPath.COURSES.LIST,
    path: allAreaComponents.courses.main,
    activeRoutes: [
      allAreaPath.COURSES.ADD_COURSE,
      '/courses/update-course/',
    ],
  },
  {
    title: "Add Course",
    icon: SiCoursera,
    route: allAreaPath.COURSES.ADD_COURSE,
    path: allAreaComponents.courses.addCourse,
    isHidden: true,
  },
  {
    title: "Update Course",
    icon: SiCoursera,
    route: allAreaPath.COURSES.UPDATE_COURSE,
    path: allAreaComponents.courses.updateCourse,
    isHidden: true,
  },
  {
    title: "Coupons",
    icon: RiCoupon2Fill,
    route: allAreaPath.COUPONS.LIST,
    path: allAreaComponents.coupons.main,
  },
  {
    title: "Orders",
    icon: FaFirstOrder,
    route: allAreaPath.ORDERS.LIST,
    path: allAreaComponents.orders.main,
    activeRoutes: ["/orders/"],
  },
  {
    title: "View Course",
    icon: FaFirstOrder,
    route: allAreaPath.COURSES.VIEW,
    path: allAreaComponents.courses.view,
    isHidden: true,
  },
  {
    title: "View Order",
    icon: FaFirstOrder,
    route: allAreaPath.ORDERS.VIEW,
    path: allAreaComponents.orders.view,
    isHidden: true,
  },
  {
    title: "Add Lesson",
    icon: FaFirstOrder,
    route: allAreaPath.COURSES.LESSONS.ADD_LESSON,
    path: allAreaComponents.courses.lessons.addLesson,
    isHidden: true,
  },
  {
    title: "Update Lesson",
    icon: FaFirstOrder,
    route: allAreaPath.COURSES.LESSONS.UPDATE_LESSON,
    path: allAreaComponents.courses.lessons.updateLesson,
    isHidden: true,
  },
  {
    title: "Profile",
    icon: FaFirstOrder,
    route: allAreaPath.PROFILE,
    path: allAreaComponents.profile,
    isHidden: true,
  },
  {
    title: "Main Settings",
    icon: IoMdSettings,
    route: "/settings",
    activeRoutes:[allAreaPath.SETTINGS.HERO],
    children: [
      {
        title: "Hero",
        route: allAreaPath.SETTINGS.HERO,
        icon: FaIntercom,
        path: allAreaComponents.settings.hero,
      },
      {
        title: "FAQ",
        route: allAreaPath.SETTINGS.FAQ,
        icon: FaQuestion,
        path: allAreaComponents.settings.faqs,
      },
    ],
  },
];
export default allAreaRoutes;

/*  ---------------------------------------------------------------------- */
