import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AppRouter from './components/appRouter';

function App() {
  return <>
    <AppRouter />
    <ToastContainer />
  </>
}

export default App;
