import { useEffect } from "react";
import { useDispatch } from "react-redux";
import authenticate from "../hoc/auth.hoc";
import { setTokens } from "../redux/slices/authToken.slice";
import { getCookie } from "../services/utils/cookie";

const AuthenticatePage = ({ children }: { children: any }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let cookie = getCookie("elearning_authenticated")
        if (cookie) cookie = JSON.parse(cookie);
        if (cookie) dispatch(setTokens(cookie));
    }, [dispatch]);

    return <div>{authenticate(children) as any}</div>;
};

export default AuthenticatePage;

/*  ---------------------------------------------------------------------- */