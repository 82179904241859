import { HttpStatusCode } from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { AuthHandlerType } from "../../../enums/auth";
import auth from "../../../services/api/auth";
import { errorToast } from "../../../services/utils/toast";
import SignUpForm from "./form";

const SignUp = ({
  toggleHandler,
}: {
  toggleHandler: (value: AuthHandlerType) => void;
}) => {
  const [signUpLoading, setSignUpLoading] = useState<boolean>(false);

  const handleSignUp = async (values: { email: string; password: any }) => {
    setSignUpLoading(true);

    try {
      let response = await auth.SignUp(values);

      if (response.statusCode === HttpStatusCode.Ok) {
        toggleHandler(AuthHandlerType.LOGIN);
        toast(response.data.message);
      } else {
        errorToast(response.message.errors[0]?.msg);
      }
    } catch (error) {
      errorToast("Something went wrong!");
    }

    setSignUpLoading(false);
  };

  return (
    <div className="container h-p100">
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center g-0">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded10 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="text-primary fw-600">Get started with Us</h2>
                  <p className="mb-0 text-fade">Register a new membership</p>
                </div>
                <div className="p-40">
                  <SignUpForm
                    loading={signUpLoading}
                    handleSignUp={handleSignUp}
                  />
                  <div className="text-center">
                    <p className="mt-15 mb-0 text-fade">
                      Already have an account?
                      <span
                        tabIndex={-1}
                        onClick={() => toggleHandler(AuthHandlerType.LOGIN)}
                        className="text-primary cursor-pointer ms-5 "
                      >
                        Sign In
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

/*  ---------------------------------------------------------------------- */