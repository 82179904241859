import { useState } from "react";
import { IconType } from "react-icons";
import { FaAngleRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

type MenuListProps = {
  menuTitle: string;
  menuIcon: IconType;
  menuList: {
    title: string;
    route: string;
    icon: IconType;
    activeRoutes?: any[];
  
  }[];
  isOpen:boolean;
  setIsOpen:(value:boolean)=>void
};

const MenuList = ({
  menuList,
  menuTitle,
  menuIcon: MenuIcon,
  isOpen,setIsOpen
}: MenuListProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <li
      style={{ cursor: "pointer" }}
      className={`treeview ${isOpen ? "menu-open active" : ""} `}
    >
      <span onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon />
        <span>{menuTitle}</span>
        <span className="pull-right-container">
          <FaAngleRight
            style={{ height: "15px" }}
            className="fa fa-angle-right pull-right"
          />
        </span>
      </span>
      <ul
        style={{ display: isOpen ? "block" : "none" }}
        className="treeview-menu"
      >
        {menuList.map(({ title, icon: Icon, route, activeRoutes }, index) => (
          <li
            onClick={() => navigate(route)}
            className={
              pathname === route ||
              activeRoutes?.find((route) => pathname.includes(route.toString()))
                ? "active"
                : ""
            }
          >
            <span>
              <i className="icon-Commit">
                <Icon />
              </i>
              {title}
            </span>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default MenuList;

/*  ---------------------------------------------------------------------- */
