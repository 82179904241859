import { useEffect, useState } from "react";
import Loader from "../../components/loader";
import { AuthHandlerType } from "../../enums/auth";
import { getCookie } from "../../services/utils/cookie";
import "../../styles/css/auth.css";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import SignUp from "./signUp";

const AuthHandler = () => {
  const [authType, setAuthType] = useState(AuthHandlerType.LOGIN);
  let [cookie, setCookie] = useState<null | undefined | any>();

  const toggleHandler = (value: AuthHandlerType) => {
    setAuthType(value);
  };

  useEffect(() => {
    let cookie = getCookie("elearning_auth");

    if (!cookie) {
      setCookie(null);
    } else {
      setCookie(JSON.parse(cookie));
    }
  }, []);

  return cookie === undefined ? (
    <Loader />
  ) : (
    <div
      style={{ height: "100vh", overflow: "auto" }}
      className="hold-transition theme-primary mainBackground bg-img"
    >
      {authType === AuthHandlerType.LOGIN && (
        <Login toggleHandler={toggleHandler} />
      )}
      {authType === AuthHandlerType.SIGNUP && (
        <SignUp toggleHandler={toggleHandler} />
      )}
      {authType === AuthHandlerType.FORGOT_PASSWORD && (
        <ForgotPassword toggleHandler={toggleHandler} />
      )}
    </div>
  );
};

export default AuthHandler;

/*  ---------------------------------------------------------------------- */