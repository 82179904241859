import React, { Suspense, useState } from "react";
import Loader from "../loader";
import Header from "./header";
import Sidebar from "./sidebar";

const Layout = ({ children }: { children: React.ReactElement }) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const handleSidebar = (value: boolean) => {
        setIsCollapsed(value);
        if (window.innerWidth <= 766) {
            if (value) document.body.classList.add("sidebar-open");
            else document.body.classList.remove("sidebar-open");
        } else {
            if (value) document.body.classList.add("sidebar-collapse");
            else document.body.classList.remove("sidebar-collapse");
        }
    };

    return (
        <div className="wrapper">
            <Header isCollapsed={isCollapsed} handleSidebar={handleSidebar} />
            <Sidebar isCollapsed={isCollapsed} />
            <Suspense fallback={<Loader />}>
                <div style={{ background: "#f6f9fb", borderRadius: "0px" }} className="content-wrapper">
                    <div className="container-full">
                        <section className="content">
                            {children}
                        </section>
                    </div>
                </div>
            </Suspense>

        </div>
    );
};

export default Layout;

/*  ---------------------------------------------------------------------- */