import { IoMdClose } from "react-icons/io";
import userAvatar from "../../assets/images/png/avatar.png";
import { getUser } from "../../services/utils/cookie";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa6";
import AppButton from "../button";
import { useSelector } from "react-redux";
import ConfirmModal from "../modals/confirmationModal";
import React, { useState } from "react";

const ProfileBar = ({
  handleCloseProfile,
  handleLogout
}: {
  handleCloseProfile: () => void;
  handleLogout:()=>void
}) => {
  const { user } = useSelector((state: any) => state.loggedInUser);

  return (
    <React.Fragment>
      <div className="modal-body p-30 bg-white">
        <div className="d-flex align-items-center justify-content-between pb-30">
          <h4 className="m-0">User Profile</h4>
          <span
            onClick={() => handleCloseProfile()}
            className="btn btn-icon btn-danger-light btn-sm no-shadow"
            data-bs-dismiss="modal"
          >
            <IoMdClose />
          </span>
        </div>
        <div>
          <div className="d-flex flex-row">
            <div className="">
              <img
                src={user?.avatar?.url ?? userAvatar}
                alt="user"
                className="rounded bg-danger-light w-150"
                width="100"
              />
            </div>
            <div className="ps-20">
              <h5 className="mb-0">{user.name}</h5>
              <p className="my-5 text-fade">Admin</p>
              <a href="mailto:dummy@gmail.com">
                <span className="icon-Mail-notification me-5 text-success">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>
                {user?.email}
              </a>
            </div>
          </div>
        </div>
        <div className="dropdown-divider my-30"></div>
        <div>
          <div className="d-flex align-items-center mb-30">
            <div className="me-15 bg-primary-light h-50 w-50 l-h-60 rounded text-center">
              <FaUser />
            </div>
            <div className="d-flex flex-column fw-500">
              <Link
                to={"/profile"}
                className="text-dark hover-primary mb-1 fs-16"
              >
                My Profile
              </Link>
              <span className="text-fade">Account settings and more</span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-30">
            <AppButton
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </AppButton>
          </div>
        </div>
      </div>
   
     
     
    </React.Fragment>
  );
};

export default ProfileBar;

/*  ---------------------------------------------------------------------- */
