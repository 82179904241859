import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthenticatePage from "../../pages/authenticatePage";
import Dashboard from "../../pages/dashboard";
import allAreaRoutes from "../../services/utils/routes";

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthenticatePage children={Dashboard} />,
      children: allAreaRoutes
        .flatMap((route) => (route.children ? route.children : route))
        .map(({ route, path: Path, index }: any) => {
          return {
            path: route,
            element: <AuthenticatePage children={Path} />,
            ...(index && { index: true }),
          };
        }) as any,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;

/*  ---------------------------------------------------------------------- */