import React from "react";
import { Button, Spinner } from "react-bootstrap";

const AppButton = ({
  className,
  type,
  children,
  isLoading,
  icon,
  onClick,
  disabled
}: {
  className?: string;
  isLoading?: boolean;
  children: any;
  type?: "button" | "submit" | "reset";
  icon?: React.ReactElement;
  onClick?: () => void;
  disabled?:boolean
}) => {
  return (
    <Button disabled={disabled} onClick={onClick} type={type ?? "button"} className={className}>
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          style={
            icon ? { display: "flex", gap: "0 10px", alignItems: "center" } : {}
          }
        >
          {icon}
          {children}
        </div>
      )}
    </Button>
  );
};

export default AppButton;

/*  ---------------------------------------------------------------------- */