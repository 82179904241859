import * as yup from "yup";

export const signUpValidation = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm password id required!")
    .oneOf([yup.ref("password"), null as any], "Passwords must match"),
  term_and_conditions: yup
    .boolean()
    .isTrue("Please accept term & conditions to continue. !"),
});

/*  ---------------------------------------------------------------------- */