import { configureStore } from "@reduxjs/toolkit";
import authTokenSlice from "./slices/authToken.slice";
import loggedInUserSlice from "./slices/loggedInUser.slice";

export const store = configureStore({
  reducer: {
    loggedInUser: loggedInUserSlice,
    authToken: authTokenSlice,
  },
  devTools: false,
});

/*  ---------------------------------------------------------------------- */
