import { HttpStatusCode } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AuthHandlerType } from "../../../enums/auth";
import { setTokens } from "../../../redux/slices/authToken.slice";
import { setUser } from "../../../redux/slices/loggedInUser.slice";
import auth from "../../../services/api/auth";
import { setCookie } from "../../../services/utils/cookie";
import { errorToast } from "../../../services/utils/toast";
import LoginForm from "./form";

const Login = ({
  toggleHandler,
}: {
  toggleHandler: (value: AuthHandlerType) => void;
}) => {
  const dispatch = useDispatch();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const handleLogin = async (values: { email: string; password: any }) => {
    setLoginLoading(true);

    try {
      const response = await auth.Login(values);

      if (response.statusCode === HttpStatusCode.Ok) {
        if (response.data.user.role !== "creator") {
          toast.error("Invalid Credentials!");
        } else {
          setCookie("elearning_authenticated", response.data.tokens);
          setCookie("elearning_user", response.data.user);
          dispatch(setTokens(response.data.tokens));
          dispatch(setUser(response.data.user));
        }
      } else {
        toast.error(response.message.errors[0]?.msg);
      }
    } catch (error) {
      errorToast("Something went wrong!");
    }
    setLoginLoading(false);
  };

  return (
    <div className="container h-p100">
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center g-0">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded10 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="text-primary fw-600">Let's Get Started</h2>
                  <p className="mb-0 text-fade">
                    Sign in to continue to Elearning.
                  </p>
                </div>
                <div className="p-40">
                  <LoginForm
                    loading={loginLoading}
                    handleLogin={handleLogin}
                    toggleHandler={toggleHandler}
                  />
                  <div className="text-center">
                    <p className="mt-15 mb-0 text-fade">
                      Don't have an account?{" "}
                      <span
                        onClick={() => toggleHandler(AuthHandlerType.SIGNUP)}
                        className="text-primary ms-5 cursor-pointer"
                      >
                        Sign Up
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

/*  ---------------------------------------------------------------------- */
