import { Formik } from "formik";
import { FaLock, FaRegUser } from "react-icons/fa";
import AppButton from "../../../components/button";
import { signUpValidation } from "../../../validations/auth/signUp.validation";

const SignUpForm = ({
  handleSignUp,
  loading,
}: {
  handleSignUp: (values: { email: string; password: any }) => void;
  loading?: boolean;
}) => {
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        confirmPassword: "",
        term_and_conditions: false,
      }}
      validationSchema={signUpValidation}
      onSubmit={(values) => {
        handleSignUp(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <div className="input-group mb-1">
              <span className="input-group-text bg-transparent">
                <FaRegUser className="text-fade ti-user" />
              </span>
              <input
                type="email"
                value={values.email}
                onBlur={handleBlur}
                name="email"
                onChange={handleChange}
                className="form-control ps-15 bg-transparent"
                placeholder="Email"
              />
            </div>
            {errors.email && errors.email && touched.email && (
              <span className="text-xs text-danger ">
                {errors.email && touched.email && errors.email}
              </span>
            )}
          </div>
          <div className="form-group mb-3">
            <div className="input-group mb-1">
              <span className="input-group-text bg-transparent">
                <FaLock className="text-fade ti-lock" />
              </span>
              <input
                onBlur={handleBlur}
                name="password"
                value={values.password}
                onChange={handleChange}
                type="password"
                className="form-control ps-15 bg-transparent"
                placeholder="Password"
              />
            </div>
            {errors.password && errors.password && touched.password && (
              <span className="text-xs text-danger ">
                {errors.password && touched.password && errors.password}
              </span>
            )}
          </div>
          <div className="form-group mb-3">
            <div className="input-group mb-1">
              <span className="input-group-text bg-transparent">
                <FaLock className="text-fade ti-lock" />
              </span>
              <input
                name="confirmPassword"
                onBlur={handleBlur}
                type="password"
                value={values.confirmPassword}
                onChange={handleChange}
                className="form-control ps-15 bg-transparent "
                placeholder="Retype Password"
              />
            </div>
            {errors.confirmPassword &&
              errors.confirmPassword &&
              touched.confirmPassword && (
                <span className="text-xs text-danger ">
                  {errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword}
                </span>
              )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="checkbox">
                <input
                  onChange={handleChange}
                  name="term_and_conditions"
                  type="checkbox"
                  id="term_and_conditions"
                />
                <label htmlFor="term_and_conditions">
                  I agree to the <span className="text-primary">Terms</span>
                </label>
              </div>
              {errors.term_and_conditions &&
                errors.term_and_conditions &&
                touched.term_and_conditions && (
                  <span className="text-xs text-danger ">
                    {errors.term_and_conditions &&
                      touched.term_and_conditions &&
                      errors.term_and_conditions}
                  </span>
                )}
            </div>
            <div className="col-12 text-center">
              <AppButton
                isLoading={loading}
                type="submit"
                className="btn btn-primary w-p100 mt-10"
              >
                REGISTER
              </AppButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SignUpForm;

/*  ---------------------------------------------------------------------- */